import { cc_format_card_number, cc_format_card_expiry, cc_format_card_cvc, cc_card_type, cc_card_expiry_val, cc_validate_card_number, cc_validate_card_expiry, cc_validate_card_cvc, cc_blurred_card_number } from 'card_utilities';

document.addEventListener("turbolinks:load", function() {
  var new_card_info_div = document.getElementById('new_card_info');
  if (new_card_info_div) {
    var cardType, cardNumber, cardExp, cardCVC, submit_tag_label;

    const cc_number_field = new_card_info_div.querySelector('.cc-number');
    const cc_exp_field = new_card_info_div.querySelector('.cc-exp');
    const cc_cvc_field = new_card_info_div.querySelector('.cc-cvc');

    cc_format_card_number(cc_number_field);
    cc_format_card_expiry(cc_exp_field);
    cc_format_card_cvc(cc_cvc_field);

    const cc_form = new_card_info_div.closest('form');
    const submit_button = cc_form.querySelector('input[type=submit].btn-primary');
    const data_descriptor_input = cc_form.querySelector('input[id$=data_descriptor]');
    const data_value_input = cc_form.querySelector('input[id$=data_value]');

    const save_card_check = cc_form.querySelector('#save_card');
    const card_description_div = cc_form.querySelector('#card-description-area');
    const card_description_input = cc_form.querySelector('#card_description');

    if (save_card_check) {
      save_card_check.addEventListener('click', function(ev) {
        if (save_card_check.checked) {
          card_description_div.style.display = 'block';
          card_description_input.required = true;
        } else {
          card_description_div.style.display = 'none';
          card_description_input.required = false;
        }
      });
    }

    function status_companion_span(field) {
      const parent_div = field.closest('.has-feedback');
      if (parent_div) {
        return parent_div.querySelector('.visually-hidden');
      } else {
        return null;
      }
    }

    function set_companion_span_text(field, val) {
      const comp_span = status_companion_span(field);
      if (comp_span) {
        comp_span.textContent = val;
      }
    }

    // for successful input
    function successful_field(field) {
      field.setCustomValidity('');
      field.classList.remove('is-invalid');
      field.classList.add('is-valid');
      set_companion_span_text(field, '(success)');
    }

    // for errors
    function error_field(field, msg) {
      field.setCustomValidity(msg);
      field.classList.add('is-invalid');
      field.classList.remove('is-valid');
      set_companion_span_text(field, msg);
    }

    // nada
    function empty_field(field) {
      field.setCustomValidity('');
      field.classList.remove('is-invalid');
      field.classList.remove('is-valid');
      set_companion_span_text(field, '');
    }

    function cc_number_blur() {
      const cc_number = cc_number_field.value;
      if (cc_number == '') {
        if (!cardNumber) {
          empty_field(cc_number_field);
        }
      } else if (cc_validate_card_number(cc_number)) {
        successful_field(cc_number_field);
        cardType = cc_card_type(cc_number);
        cardNumber = cc_number;
        const blurred_placeholder = cc_blurred_card_number(cc_number);
        cc_number_field.value = '';
        cc_number_field.required = false;
        cc_number_field.placeholder = blurred_placeholder;
      } else {
        error_field(cc_number_field, "Invalid card number");
        cc_number_field.required = true;
        cc_number_field.placeholder = '•••• •••• •••• ••••';
        cardNumber = undefined;
      }
    }

    cc_number_field.addEventListener('blur', cc_number_blur);

    function cc_exp_blur() {
      const cc_exp = cc_exp_field.value;
      if (cc_exp == '') {
        empty_field(cc_exp_field);
        cardExp = undefined;
      } else if (cc_validate_card_expiry(cc_card_expiry_val(cc_exp))) {
        successful_field(cc_exp_field);
        cardExp = cc_card_expiry_val(cc_exp);
      } else {
        error_field(cc_exp_field, "Invalid card expiry");
        cardExp = undefined;
      }
    }

    cc_exp_field.addEventListener('blur', cc_exp_blur);

    function cc_cvc_blur() {
      const cc_cvc = cc_cvc_field.value;
      if (cc_cvc == '') {
        empty_field(cc_cvc_field);
        cardCVC = undefined;
      } else if (cc_validate_card_cvc(cc_cvc, cardType)) {
        successful_field(cc_cvc_field);
        cardCVC = cc_cvc;
      } else {
        error_field(cc_cvc_field, "Invalid CVC");
        cardCVC = undefined;
      }
    }

    cc_cvc_field.addEventListener('blur', cc_cvc_blur);

    function responseHandler(response) {
      if (response.messages.resultCode === "Error") {
        for (var i = 0; i < response.messages.message.length; i++) {
          console.log(response.messages.message[i].code + ": " + response.messages.message[i].text);
        }

        submit_button.disabled = false;
        submit_button.value = submit_tag_label;

        cardNumber = undefined;
        empty_field(cc_number_field);
        cc_number_field.value = '';
        cc_number_field.placeholder = '•••• •••• •••• ••••';
        cc_number_field.focus();

        setTimeout(() => alert("Your card was declined"), 0);
      } else {
        data_descriptor_input.value = response.opaqueData.dataDescriptor;
        data_value_input.value = response.opaqueData.dataValue;
        cc_form.submit();
      }
    }

    cc_form.addEventListener('submit', function(event) {
      var secureData = {}, authData = {}, cardData = {};

      const data_descriptor = data_descriptor_input.value;
      const data_value = data_value_input.value;

      if (data_descriptor && data_value) {
        return true;
      } else {
        if (!cardNumber) { cc_number_blur() }
        if (!cardExp) { cc_exp_blur() }
        if (!cardCVC) { cc_cvc_blur() }
        if (cardNumber && cardExp && cardCVC) {
          submit_tag_label = submit_button.value;
          submit_button.disabled = true;
          submit_button.value = 'Please Wait...';

          cardData.cardNumber = cardNumber.replace(/[^\d]+/g,'');
          // Month must be two digits
          cardData.month = ("0" + cardExp.month).substr(-2);
          // Yes, I know this breaks in 3000.  I'll probably be dead.
          cardData.year = cardExp.year.toString().replace(/^2\d(\d\d)/,'$1');
          cardData.cardCode = cardCVC;

          secureData.cardData = cardData;

          authData.clientKey = authnet_public_client_key;
          authData.apiLoginID = authnet_api_login_id;
          secureData.authData = authData;

          Accept.dispatchData(secureData, responseHandler);

          event.preventDefault();
          return false;
        } else if (cc_form.classList.contains('edit_authnet_card')) {
          // ignore if all blank, warn otherwise
          if (cardNumber || cardExp || cardCVC) {
            alert("All fields are required to update your card number or expiration date.  If you just wish to update the card description, leave all other fields empty.");
            return false;
          }
        } else {
          alert("Please complete your credit card information.");
          event.preventDefault();
          return false;
        }
      }
    });
  }
});
