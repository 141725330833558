import * as clipboard from "clipboard-polyfill/dist/promise/clipboard-polyfill.promise";
import {show_toast} from "toasts";

document.addEventListener("turbolinks:load", function() {
  let clipboard_buttons = document.querySelectorAll('.clipboard-copy');

  for (let clippy_button of clipboard_buttons) {
    const target_id = clippy_button.dataset['clipboardTarget'];
    if (target_id) {
      const target = document.querySelector(target_id);
      if (target) {
        clippy_button.addEventListener('click', function(e) {
          let value;
          if (target.nodeName == 'INPUT') {
            value = target.value;
          } else {
            value = target.innerHTML.replace(/^\s+/, '').replace(/\s+$/, '');
          }
          clipboard.writeText(value);
          show_toast(null, "Link copied to clipboard");
        });
      }
    }
  }
});
