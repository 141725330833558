document.addEventListener("turbolinks:load", function() {
  const csrf_token = document.querySelector('meta[name=csrf-token]').content;
  let not_sub_checks = document.querySelectorAll('input[type=checkbox][name^=not_sub]');

  for (let not_sub_check of not_sub_checks) {
    const product_template_id = not_sub_check.dataset.productTemplateId;
    const notification_type = not_sub_check.dataset.notificationType;
    const notification_subscription_path = not_sub_check.dataset.notificationSubscriptionPath;

    not_sub_check.addEventListener('change', function(e) {
      let notification_subscription_id = not_sub_check.dataset.notificationSubscriptionId;

      if (not_sub_check.checked) {
        if (notification_subscription_id) {
          // Really strange - do nothing
        } else {
          // Need to create a subscription notification
          fetch(`${notification_subscription_path}.json`, {
            method: 'post',
            body: JSON.stringify({ product_template_id: product_template_id, subscription_type: notification_type }),
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrf_token
            },
            credentials: 'same-origin'
          }).then(function(response) {
            if (response.status == 201) {
              return response.json();
            }
          }).then(function(data) {
            // if the response is fine, set the subNotId data
            if (data) {
              not_sub_check.dataset['notificationSubscriptionId'] = data['id'];
            }
          });
        }
      } else {
        // If we get here, the box is unchecked
        if (notification_subscription_id) {
          fetch(`${notification_subscription_path}/${notification_subscription_id}.json`, {
            method: 'delete',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrf_token
            },
            credentials: 'same-origin'
          }).then(function(response) {
            // if the response is fine, we can remove the data
            if (response.status == 204) {
              delete not_sub_check.dataset['notificationSubscriptionId'];
              return true;
            }
          });
        } else {
          // It seems that there's no sub notification id, so nothing to
          // do
        }
      }
      e.preventDefault();
      e.stopPropagation();
    });
  }
});
