/* Easy interface to bootstrap toasts */

export const show_toast = function(header_html, body_html) {
  // This is a toast with header + body
  var complete_toast = '\
    <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">\
      <div class="toast-header">\
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>\
      </div>\
      <div class="toast-body">\
      </div>\
    </div>\
  ';

  // This is a toast with body only
  var tiny_toast = '\
    <div class="toast align-items-center" role="alert" aria-live="assertive" aria-atomic="true">\
      <div class="d-flex">\
        <div class="toast-body">\
        </div>\
        <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>\
      </div>\
    </div>\
  ';

  var template, header_elements, body_elements;

  var dom_parser = new DOMParser();
  // To parse, use:
  //    doc = dom_parser.parseFromString(html_string, 'text/html')
  // Get the relevant node from that:
  //    dom_root = doc.getRootNode().body.firstChild
  // Prepend to something:
  //    nice_div.prepend(dom_root)

  if (header_html) {
    template = dom_parser.parseFromString(complete_toast, 'text/html').getRootNode().body.firstChild;
  } else {
    template = dom_parser.parseFromString(tiny_toast, 'text/html').getRootNode().body.firstChild;
  }

  // Note that this might seem a bit convoluted, but there's possibly
  // a button at the end of the div already, so this ensures that the
  // button remains at the end.
  if (header_html) {
    var template_header = template.querySelector('div.toast-header');
    template_header.insertAdjacentHTML('afterbegin', header_html);
  }

  if (body_html) {
    var template_body = template.querySelector('div.toast-body');
    template_body.insertAdjacentHTML('afterbegin', body_html);
  }

  // At this point, our template should be filled in and ready to add
  // to the toasts div.
  var toasts_container_div = document.getElementById('toast-container');
  if (toasts_container_div) {
    toasts_container_div.append(template);
    var this_toast = new bootstrap.Toast(template);
    this_toast.show();
    template.addEventListener('hidden.bs.toast', function() {
      this_toast.dispose();
      template.remove();
    });
  }
}
