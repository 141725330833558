document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll('.clear-form-btn').forEach(function(clearFormButton) {
    var form = clearFormButton.closest('form');
    clearFormButton.addEventListener('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      clear_form(form);
    });

    function clear_form(f) {
      set_form_fields(f);
    }

    function set_form_fields(f, args) {

      var fnum;
      for (fnum = 0 ; fnum < f.elements.length ; fnum++) {

        var field = f.elements[fnum]
        var fname = field.name

        if (fname=='authenticity_token') {
          // nada
          } else if (fname=='utf8') {
            // nada
          } else if (fname=='search[sort_order]') {
            // nada
          } else if (fname=='search[direction]') {
            // nada
          } else if (fname=='search[search_type]') {
            // nada
          } else if (!args || !args[fname]) {
             // clear it out
             if (field.type=='select-one' || field.type=='select-multiple') {
                field.selectedIndex=0;
             } else if (field.type=='submit' || field.type=='reset' || field.type=='button') {
                // Do nothing for buttons
             } else if (field.type=='checkbox' || field.type=='radio') {
                // checkboxes and radios need to be clears
                field.checked=false;
             } else {
                field.value='';
             }
          } else {
             // set it to the value of args[fname]
             if (field.type=='select-one' || (field.type=='select-multiple' && typeof(args[fname])=='string')) {
                for (var i=0 ; i<field.options.length ; i++) {
                   field.options[i].selected=false;
                   if (field.options[i].value==args[fname]) {
                      field.options[i].selected=true;
                      break;
                   }
                }
             } else if (field.type=='select-multiple') {
                for (var i=0 ; i<field.options.length ; i++) {
                   for (var j=0 ; j<args[fname].length ; j++) {
                      if (field.options[i].value==args[fname][j]) {
                         field.options[i].selected=true;
                         break;
                      }
                   }
                }
             } else if (field.type=='submit' || field.type=='reset' || field.type=='button') {
                // Do nothing for buttons
             } else if (field.type=='checkbox' || field.type=='radio') {
                if (field.value==args[fname]) {
                  field.checked=true;
                }
             } else {
                field.value=args[fname];
             }
          }
       }
    }
  });
});
