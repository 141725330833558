document.addEventListener("turbolinks:load", function() {
  let different_billing_address_field = document.getElementById('different_billing_address');
  let new_billing_address_field = document.getElementById('new_billing_address');
  let new_billing_address_fields = document.getElementById('new_billing_address_fields');
  let billing_addresses_div = document.getElementById('billing_addresses');

  if (new_billing_address_fields) {
    let company_name_field = new_billing_address_fields.querySelector('input[type=text][name="address[company_name]"]');
    let first_name_field = new_billing_address_fields.querySelector('input[type=text][name="address[first_name]"]');
    let last_name_field = new_billing_address_fields.querySelector('input[type=text][name="address[last_name]"]');

    if (different_billing_address_field) {
      let validate_address_form = false;

      function setup_billing_address_form() {
        var different_billing_address = false;

        if (different_billing_address_field.type == 'hidden') {
          different_billing_address = different_billing_address_field.value;
        } else if (different_billing_address_field.type == 'checkbox') {
          different_billing_address = different_billing_address_field.checked;
        }

        var new_billing_address = false;

        if (new_billing_address_field.type == 'hidden') {
          new_billing_address = new_billing_address_field.value;
        } else if (new_billing_address_field.type == 'radio') {
          new_billing_address = new_billing_address_field.checked;
        }

        if (different_billing_address) {
          billing_addresses_div.style.display = 'block';
          // console.log("show billing addresses");
        } else {
          billing_addresses_div.style.display = 'none';
          // console.log("hide billing addresses");
        }

        if (different_billing_address && new_billing_address) {
          billing_addresses_div.querySelectorAll('input.required').forEach(function(e) { e.required = true; });
          new_billing_address_fields.style.display = 'block';
          validate_address_form = true;
          // console.log("new billing address fields required");
        } else {
          billing_addresses_div.querySelectorAll('input.required').forEach(function(e) { e.required = false; });
          new_billing_address_fields.style.display = 'none';
          validate_address_form = false;
          // console.log("new billing address fields not required");
        }
      }

      document.querySelectorAll('input#different_billing_address, input[name=billing_address_choice]').forEach(function(x) {
        x.addEventListener('change', setup_billing_address_form);
      });

      setup_billing_address_form();

      let order_form = new_billing_address_fields.closest('form');

      function check_company_name_validity_running(event) {
        if (company_name_field.value || (first_name_field.value && last_name_field.value)) {
          company_name_field.setCustomValidity("");
        }
      }

      company_name_field.addEventListener('change', check_company_name_validity_running);
      first_name_field.addEventListener('change', check_company_name_validity_running);
      last_name_field.addEventListener('change', check_company_name_validity_running);

      order_form.addEventListener('submit', function(event) {
        if (validate_address_form) {
          // require either company_name or first_name and last_name
          // to not be blank
          if (!company_name_field.value && (!first_name_field.value || !last_name_field.value)) {
            company_name_field.setCustomValidity("You must provide either a company name or a first and last name to continue.");
            alert("You must provide either a company name or a first and last name to continue.");
            event.preventDefault();
            event.stopPropagation();
            return false;
          }
        }
        company_name_field.setCustomValidity("");
        return true;
      }, {capture: true});
    }
  }
});
